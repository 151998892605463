<template lang="pug">
footer
  .copy &copy 'ACAJ inc.'
</template>

<script>

export default {
  name: 'Footer',
};
</script>

<style scoped lang="stylus">

</style>
